import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`About a year ago, at `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{`, we published our first data insights report after having processed 50 million messages. A year later, we’ve processed over 10.5 billion messages and have more insights to share.`}</p>
    <p>{`We often show what the most common messages are into chatbots. We wanted to share data on the responses as well.`}</p>
    <p>{`The following are the top messages into bots as well as the top stickers sent to bots over the past two months.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/09/06213520/0_Py9BfY4pkOXu5NiN-745x1024.png)
    </figure>
The top messages are still “hi” and “hello” — it’s the natural way we start
conversations. More than 50% of chatbots received these messages over the past
two months, but how do they respond?
    <p>{`The good news is about 68% of bots respond in a somewhat appropriate way — with some form of greeting, intro, or welcome message. This is up from the same time last year. In August of last year, only 60% of bots responded with an appropriate message.`}</p>
    <p>{`The downside is 32% of bots last month responded with unrelated, error, or I don’t know messages, and a small percentage didn’t respond at all! This can be a huge turn off and send the conversation in the wrong direction right from the start.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/09/06213518/0_AlH6hnJ-4XxHIpFE.png)
    </figure>
The space is still relatively new. Having a response for help, can actually be
helpful!
    <p>{`About 20% of bots over the past two months received a “help” message from users. However, only about 50% of those responded with some form of help, menu options, or even an acknowledgement of the request.`}</p>
    <p>{`Some bots rely heavily on broadcast messaging — especially sports and news related bots which send updates to users periodically.`}</p>
    <p>{`Chat interfaces can be great for this — to easily communicate with your users and keep them updated on the latest info.`}</p>
    <p>{`However, it’s important to give users the ability to manage, pause, or stop these messages.`}</p>
    <p>{`More than 20% of bots receive a “stop” message, yet only about 40% of these actually respond appropriately — giving the user info on how to stop messages or update their settings, confirming the stopping of messages, or even just outright stopping.`}</p>
    <p>{`Adding stop or pause functionality can be the difference between a blocked user and a retained one. One of our customers is a sports bot and they noticed fans would block their bot when their favorite teams were losing. The company paid to acquire these users, and then had to pay to re-acquire them after being blocked. Adding a mute functionality enabled users to pause score updates when their team was losing, and enabled the company to retain the user.`}</p>
    <p>{`Growing up, we’re taught to be polite — remember your please and thank-yous.`}</p>
    <p>{`Users do thank bots.`}</p>
    <p>{`Of the bots that received “thanks,” about 60% respond with an appropriate message — including a sign of appreciation, a welcome, or thank-you back.`}</p>
    <p>{`About 40% however respond with a typical error, I don’t know, or unrelated message.`}</p>
    <p>{`Responding to common messages can help increase engagement.`}</p>
    <p>{`We’ve seen it with customers who add support for these common messages as well as adding responses for different content types. One of our customers receives images and audio from their users even though it’s not a core use case of the bot — it’s another way users communicate however. They decided to respond to these content types in a fun and quirky way and they saw engagement go up overall.`}</p>
    <p>{`While responding to these common messages can increase engagement, it’s important to look at your own data and see what users are saying, or sending, to your bot.`}</p>
    <p>{`The beauty of conversational interfaces is that users will tell you what they want from the bot and what they think of it after, which sometimes isn’t too pleasant. It is an opportunity though to learn what your users want, and decide whether you want to add support for those use cases.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/09/06213517/34d51-1weemunljmmujpsadd4as3w.png)
    </figure>
The good news is 30 day retention in Facebook bots is still on par with mobile
apps. We currently see a 34% retention rate in Facebook compared to [36% in
mobile](http://info.localytics.com/blog/mobile-apps-whats-a-good-retention-rate)
(based on Localytics). Interestingly enough, men tend to have higher 30 day
retention rates — 38.5% vs 29.3% for women.
    <p>{`Conversation is two way, it’s important to monitor the incoming and outgoing messages of your bot and iterate on responses. Doing so can increase user engagement and retention.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, Twitter, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      